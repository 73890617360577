import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {path:'account', loadChildren:()=>import('./account/account.module').then(x=>x.AccountModule)},
  {path:'**', redirectTo:'account'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
